import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import AppContext from '../contexts/AppContext';

import StepWizard from 'react-step-wizard';
import styled from 'styled-components';

import FirstFeedbackStep from '../components/FirstFeedbackStep';
import SecondFeedbackStep from '../components/SecondFeedbackStep';
import ThirdFeedbackStep from '../components/ThirdFeedbackStep';
import DefaultFeedbackStep from '../components/DefaultFeedbackStep';
import { intercomShutdown } from '../utils/intercom';
import i18n from '../i18n';
import { showError } from '../services/toastService';

import { logAnalytics } from '../services/analyticsLogger';
import {
    postChatFeedbackV2,
} from '../services/feedbackService';

import { localStorageKeysMap } from '../utils/feedbackUtils';

const Wrapper = styled.div`
  flex: 1;  
  justify-content: center;
  align-items: center;
`;

const StyledStepWizard = styled(StepWizard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: auto;
  background-color: aliceblue;
  border-radius: 16px;
  box-shadow: 0px 8px 16px 8px rgba(21, 21, 41, 0.25);
  height: calc(100vh - 60px);
  margin: 30px auto;
  width: 100%;
  max-width: 560px;
  @media (max-width: 768px) {
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    height: 100vh;
    width: 100%;
  }
  > div {
    width: 100%;
    max-width: 560px;
  }
`;

const initialFeedbackValues = {
  stars: 0,
  improvements: [],
  freeText: '',
  contactWanted: false,
};

const errorResponses = {
  'Either prebookingId or chatRoomId must be provided': 'failed_to_find_prebooking_data',
  'No prebooking found with id': 'failed_to_find_prebooking_data',
  'Invalid prebooking id': 'failed_to_find_prebooking_data',
  'Invalid chat room id': 'failed_to_find_prebooking_data',
  'Invalid invoice user group id': 'failed_to_find_prebooking_data',
  'Prebooking was cancelled': 'prebooking_was_cancelled',
  'Pre-booked interpretation has not yet started': 'feedback_not_open_yet'
};

const FeedbackV2 = () => {
  const { user } = useContext(AppContext);

  const location = useLocation();
  const history = useHistory();

  const search = useLocation().search;
  const userIdFromURL = new URLSearchParams(search).get('userId');
  const chatroomIdFromURL = new URLSearchParams(search).get('chatroomId');
  const prebookingIdFromURL = new URLSearchParams(search).get('prebookingId');
  const invoiceRefFromURL = new URLSearchParams(search).get('invoiceRef');
  const invoiceUserGroupIdFromURL = new URLSearchParams(search).get('invoiceUserGroupId');
  const interpretationDateFromURL = new URLSearchParams(search).get('interpretationDate');
  const aiStatusFromURL = new URLSearchParams(search).get('ai');

  const [ isFeedbackOpenedByLink ] = useState(userIdFromURL);

  const [ interpretationDate ] = useState(interpretationDateFromURL || '');
  const [ userId ] = useState(user.id || userIdFromURL || '');
  const [ aiStatus ] = useState(aiStatusFromURL ? JSON.parse(aiStatusFromURL) : location?.state?.ai || false);
  const [ chatroomId ] = useState(location?.state?.chatroomId || location?.state?.roomId || chatroomIdFromURL || null);
  const [ prebookingId ] = useState(location?.state?.prebookingId || prebookingIdFromURL || null);
  const [ prebooking ] = useState(location?.state?.prebooking || {
    invoiceRef: invoiceRefFromURL || '',
    invoiceUserGroup: {
      id: invoiceUserGroupIdFromURL || ''
    } 
  } || null);
  const [ backUrl ] = useState(location?.state?.from || '/');

  const [ header, setHeader ] = useState('');
  const [ currentPath ] = useState(isFeedbackOpenedByLink ? ['howDidItGo', 'howCanWeImprove', 'thankYouMessage'] : ['howDidItGo', 'howCanWeImprove']);
  const [ pageIndex, setPageIndex ] = useState(0);
  const [ stepWizardInstance, setStepWizardInstance ] = useState(null);
  const [ improvements ] = useState(aiStatus ? user.feedbackImprovementsAi : user.feedbackImprovements);
  const [ feedback, setFeedback ] = useState(initialFeedbackValues);
  const [ indicator, setIndicator ] = useState(false);
  const [ feedbackErrorKey, setFeedbackErrorKey ] = useState(localStorage.getItem(localStorageKeysMap.feedbackError) || null);
  const [ isMobileWidth, setIsMobileWidth ] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobileWidth(true);
    }
    intercomShutdown();
  }, []);

  useEffect(() => {
    const now = new Date().getTime();
    if (interpretationDate && now < interpretationDate) {
      const errorKey = 'feedback_not_open_yet';
      setFeedbackErrorKey(errorKey);
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

  useEffect(() => {
    setHeader(pageIndex <= currentPath.length - 1 ?
      `${pageIndex + 1} / ${currentPath.length}` : null);
  }, [pageIndex, currentPath]);

  const nextStep = () => {
    if (stepWizardInstance) {
      stepWizardInstance.nextStep();
      logAnalytics('Move to the next feedback step');
    }
  };

  const previousStep = () => {
    if (stepWizardInstance) {
      stepWizardInstance.previousStep();
      logAnalytics('Back to the previous feedback step');
    }
  };

  const backPagePath = useMemo(() => {
    if (location?.state?.roomId) {
      return '/booking';
    } else {
      return backUrl;
    }
  }, [location?.state?.roomId, backUrl]);

  const handleRatingChange = (field, value) => {
    setFeedback({
      ...feedback,
      [field]: value,
    });
  };

  const handleImprovementSelection = (improvement) => {
    setFeedback((prev) => {
      return {
        ...prev, 
        improvements: prev.improvements.find(i => i.key === improvement.key) 
          ? prev.improvements.filter(item => item.key !== improvement.key) 
          : [...prev.improvements, improvement]};
    });
  };

  const handleOpenFeedbackChange = (value) => {
    setFeedback({
      ...feedback,
      freeText: value,
    });
  };

  const handleContactChange = () => {
    setFeedback({
      ...feedback,
      contactWanted: !feedback.contactWanted,
    });
  };

  const getStarsValues = (skipped) => {
    const isTechnical = feedback.improvements.filter((item) => item.key.includes(':tech')).length > 0;
    const isUser = feedback.improvements.filter((item) => item.key.includes(':interpretation')).length > 0;
    if (skipped) {
      return {
        starsGeneral: 0, 
        starsTechnical: 0, 
        starsUser: 0,
      };
    }
    return {
      starsGeneral: feedback.stars, 
      starsTechnical: isTechnical ? feedback.stars : 0, 
      starsUser: isUser ? feedback.stars : 0,
    };
  };

  const handleSubmit = async(skipped) => {
    const stars = getStarsValues(skipped);
    const feedbackValues = skipped ? initialFeedbackValues : feedback;
    const improvementsKeys = feedback.improvements.map(i => i.key);    
    
    const preparedFeedback = { 
      ...feedbackValues, 
      ...stars,
      improvements: improvementsKeys,
      userId,
      chatroomId,
      prebookingId,
      invoiceRef: prebooking?.invoiceRef || '',
      invoiceUserGroupId: prebooking?.invoiceUserGroup?.id
    }; // one score value for all three categories

    setIndicator(true);
    try {
      if (chatroomId || prebookingId) {
        await postChatFeedbackV2(preparedFeedback);
        logAnalytics('Feedback submitted');
        if (!isFeedbackOpenedByLink) {
          history.replace(backPagePath);
        }
        localStorage.removeItem(localStorageKeysMap.feedbackError);
      }

    } catch (err) {
      const errorKey = Object.entries(errorResponses).reduce((memo, el) => {
        if (err.message.includes(el[0])) {
          memo = el[1];
        }
        return memo;
      }, 'error500');
      setFeedbackErrorKey(errorKey);

      console.error(err);
      logAnalytics('Feedback submission failed');
      showError(i18n('failedToSendFeedback'));
      localStorage.setItem(localStorageKeysMap.feedbackError, errorKey);
    }
    setIndicator(false);
  };

  const handleStepChange = (stepData) => {
    setPageIndex(stepData.activeStep - 1);
  };

  const isShowDefaultStep = useMemo(() => {
    return feedbackErrorKey;
  }, [feedbackErrorKey]);

  return (
    <Wrapper>
      {isShowDefaultStep && (
        <StyledStepWizard
          isHashEnabledinstance={setStepWizardInstance}
          initialStep={1}
          onStepChange={handleStepChange}
          isLazyMount={true}
        >
          <DefaultFeedbackStep 
            header={header}
            errorKey={feedbackErrorKey}
            indicator={indicator}
          />
        </StyledStepWizard>
      )}
      {!isShowDefaultStep && (
        <StyledStepWizard
          isHashEnabledinstance={setStepWizardInstance}
          initialStep={1}
          onStepChange={handleStepChange}
          isLazyMount={true}
        >
          <FirstFeedbackStep
            header={header}
            nextStep={nextStep}
            previousStep={previousStep}
            skipStep={handleSubmit}
            stars={feedback.stars}
            improvements={improvements}
            selectedImprovements={feedback.improvements}
            handleImprovementSelection={handleImprovementSelection}
            handleRatingChange={handleRatingChange}
            indicator={indicator}
          />
          <SecondFeedbackStep
            header={header}
            nextStep={nextStep}
            previousStep={previousStep}
            skipStep={handleSubmit}
            selectedImprovements={feedback.improvements}
            handleImprovementSelection={handleImprovementSelection}
            contactWanted={feedback.contactWanted}
            handleContactChange={handleContactChange}
            openFeedback={feedback.freeText}
            handleOpenFeedbackChange={handleOpenFeedbackChange}
            handleSubmit={handleSubmit}
            indicator={indicator}
            isMobileWidth={isMobileWidth}
            isShowThirdStep={isFeedbackOpenedByLink}
          />
          <ThirdFeedbackStep
            header={header}
            content={{
              title: i18n('thank_you_for_your_feedback'),
              message: i18n('feedback_do_next')
            }}
            indicator={indicator}
            isMobileWidth={isMobileWidth}
          />
        </StyledStepWizard>
      )}
    </Wrapper>
  );
};

export default FeedbackV2;
